import type { LinksFunction } from '@remix-run/node';
import { Outlet, useLocation } from '@remix-run/react';
import type { ReactNode } from 'react';
import { Logo } from '~/components/logo';
import { cn } from '~/utils/cn';

export const links: LinksFunction = () => {
  return [
    { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
    { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossOrigin: 'anonymous' }
  ];
};

export default function Auth() {
  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
}

function AuthLayout({ children }: { children: ReactNode }) {
  const location = useLocation();
  return (
    <div
      style={{ backgroundImage: 'url(/wavy-bg.png)' }}
      className="flex min-h-screen flex-col items-center justify-center bg-teal-100 bg-[length:500px_500px] bg-repeat px-4 py-10 bg-blend-multiply">
      <div
        className={cn(
          'w-full rounded-lg border-2 border-black bg-white shadow-[5px_5px_0px_0px_#000,4px_4px_0px_0px_#000,3px_3px_0px_0px_#000,2px_2px_0px_0px_#000,1px_1px_0px_0px_#000]',
          location.pathname === '/register' ? 'max-w-md lg:max-w-4xl' : 'max-w-md p-10'
        )}>
        {location.pathname !== '/register' && <Logo className="mx-auto mb-8 h-8" />}

        {children}
      </div>
    </div>
  );
}
